<svelte:options tag="zoom-buttons"/>

<script lang="ts">
    import { onMount } from 'svelte';

    let textElements: NodeListOf<HTMLElement>;

    onMount(async () => {
        textElements = document.querySelectorAll("#changeableFontSizeArea *");
    });

    function zoomIn() {
        textElements.forEach(element => {
            const currentFontSize = parseInt(window.getComputedStyle(element).fontSize);
            element.style.fontSize = (currentFontSize + 2) + "px";
        });
    }

    function zoomOut() {
        textElements.forEach(element => {
            const currentFontSize = parseInt(window.getComputedStyle(element).fontSize);
            element.style.fontSize = (currentFontSize - 2) + "px";
        });
    }
</script>

<div class="component__zoom-buttons flex">
    <button on:click="{zoomIn}" aria-label="Gjør tekst større">A+</button>
    <button on:click="{zoomOut}" aria-label="Gjør tekst mindre">A-</button>
</div>

<style>
    @import '/static/website/bundle.css';
</style>
